.main-div {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    padding: 2rem;
    padding-right: 8.5rem;
    padding-left: 8.5rem;
    h1{
      text-transform: initial;
      text-align: center;
      padding: 2rem;
    }
  
    h3{
      text-transform: initial;
      padding-top: 2rem;
      padding-bottom: 2rem;
  
    }
  
    .cart-table-content table tbody > tr td {
      font-size: 15px;
      text-align: start!important;
    }
    .cart-table-content table tbody > tr td.product-name a{
      font-size: 13px;
    }
  }
  
  .shop-review {
    width: 100%;
  }