.button{
    font-size: 16px;
            color: #fff;
            display: inline-block;
            border: 1px solid $theme-color;
            background-color: $theme-color;
            text-transform: uppercase;
            line-height: 1;
            padding: 19px 50px 19px;
            &:hover {
                border: 1px solid $secundary-color;
                background-color: $secundary-color;
                color: #fff;
            }
            @media #{$md-layout}{
                padding: 15px 35px 15px;
            }
            @media #{$xs-layout}{
                padding: 12px 30px 12px;
                font-size: 14px;
            }
}

.input {
    width: 20rem!important;
    background: #fff;
    border: 2px solid $theme-color;
    box-shadow: none;
    padding-left: 12px;
    height: inherit!important;
    font-size: 15px;
    color: #333;
    padding-top: 13px;
    padding-bottom: 16px;
    margin-right: 1rem;
}